<template>
  <div>
    <a-modal :visible="visible" :title="addFlag ? '新增' : '编辑'" @cancel="cancelHandler" @ok="okHandler">
      <a-form-model
        ref="formState"
        :model="formState"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 16 }"
        :rules="addFlag ? rules : undefined"
      >
        <a-form-model-item label="中控型号" prop="model">
          <a-input :disabled="!addFlag" v-model="formState.model" placeholder="请输入中控型号" />
        </a-form-model-item>
        <a-form-model-item label="最后修改时间" prop="lastModificationTime" v-if="!addFlag">
          <a-input :disabled="!addFlag" :value="formState.lastModificationTime | parseTime | orNA" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-textarea v-model="formState.remark" placeholder="备注" :rows="4" :max-length="64" />
          <span>{{ formState.remark ? formState.remark.length : 0 }}/64</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'CpuModelAddOrEditModal',
  props: ['visible', 'item'],
  data() {
    return {
      formState:
        this.item === undefined
          ? {
              model: '',
              remark: '',
            }
          : this.item,
      rules: {
        model: [{ required: true, validator: this.validateModel, trigger: 'blur' }],
      },
    };
  },
  methods: {
    cancelHandler() {
      this.$emit('update:visible', false);
    },
    okHandler() {
      this.$refs.formState.validate(async (relsValid) => {
        if (relsValid) {
          if (this.addFlag) {
            await this.$apiManager.cpuModel.addCpuModel(this.formState);
          } else {
            const payload = {
              fields: ['remark'],
              data: this.formState,
            };
            await this.$apiManager.cpuModel.updateCpuModel(payload);
          }
          this.$message.success(this.addFlag ? '添加成功' : '修改成功');
          this.$emit('updateView');
          this.$emit('update:visible', false);
        }
      });
    },
    validateModel(fields, option, callback) {
      if (option === '') {
        callback('中控型号 不能为空');
        return;
      }
      // 只允许有大小写字母、整数、-、_规则，且以字母开头
      const regex = /^[a-zA-Z][a-zA-Z0-9_-]*$/;
      if (!regex.test(option)) {
        callback('中控型号以字母开头，只允许有大小写字母、整数、"-"、"_"');
        return;
      }
      callback();
    },
  },
  computed: {
    addFlag() {
      return this.item === undefined;
    },
  },
};
</script>
<style lang="less" scoped></style>
