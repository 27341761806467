<template>
  <a-modal :footer="null" :visible="visible" title="详情" @cancel="cancelHandler" class="detail-modal">
    <a-descriptions :column="1" bordered size="small" class="labelCenter">
      <a-descriptions-item label="中控型号">{{ item.model }}</a-descriptions-item>
      <a-descriptions-item label="中控型号id">{{ item.id }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ item.remark }}</a-descriptions-item>
      <a-descriptions-item label="创建时间">{{ item.createdTime | parseTime | orNA }}</a-descriptions-item>
      <a-descriptions-item label="最后修改时间">{{ item.lastModificationTime | parseTime | orNA }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>
<script>
export default {
  name: 'CpuModelDetailModal',
  props: ['visible', 'item'],
  data() {
    return {};
  },
  methods: {
    cancelHandler() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="less" scoped>
.labelCenter {
  /deep/.ant-descriptions-item-label {
    text-align: center;
    width: 200px;
  }
  /deep/ .ant-descriptions-item-content {
    text-align: center;
  }
}
</style>
